





















































































































.accordion {
  .card,
  .card * {
    border: 0;
  }
  .card-header {
    padding: 0;
  }
  .card-body {
    background-color: #f2f4f6;
  }
}
.faq-box {
  .content-title {
    font-size: clamp(15px, 3vw, 18px);
    text-align: initial;
    line-height: 120%;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .content-detail {
    font-size: clamp(14px, 3vw, 16px);
    text-align: initial;
    line-height: 120%;
    font-weight: 600;
    text-align: initial;
    margin-bottom: 10px;
    color: #333;
    span {
      font-weight: 700;
      color: #333;
    }
  }
}
